import {
  atom,
  getDefaultStore,
  useAtom,
  useAtomValue,
  useSetAtom,
} from 'jotai';
import { useConfig } from '@/config';
import { useIsUserAuthenticated } from '@/store/store';

const isLycheeOpenAtom = atom(false);
const lycheeInitialPageAtom = atom<'results' | null>(null);

/**
 * Custom hook for managing the open/close state of the Lychee modal.
 * This hook emulates the disclosure interface but is backed by a Jotai atom.
 *
 * @returns {Object} An object containing:
 *   - isOpen: A boolean indicating whether the Lychee modal is open.
 *   - onOpen: A function to open the Lychee modal.
 *   - onClose: A function to close the Lychee modal.
 *   - onToggle: A function to toggle the open/close state of the Lychee modal.
 */
export const useLycheeDisclosure = () => {
  const [isOpen, setIsOpen] = useAtom(isLycheeOpenAtom);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen((prev) => !prev);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

/**
 * Custom hook to check if the Lychee feature is enabled for the current user.
 *
 * @returns {boolean} True if the user is authenticated and Lychee is enabled in the config.
 */
export const useIsLycheeEnabled = () => {
  const isAuthenticated = useIsUserAuthenticated();
  const isEnabled = useConfig().lychee_enabled;
  return isAuthenticated && isEnabled;
};

export function setLycheeInitialPage(page: 'results' | null) {
  const { set } = getDefaultStore();
  console.log('setting initial page', page);
  set(lycheeInitialPageAtom, page);
  set(isLycheeOpenAtom, true);
}

export function useLycheeInitialPage() {
  return useAtomValue(lycheeInitialPageAtom);
}

export function useSetLycheeInitialPage() {
  return useSetAtom(lycheeInitialPageAtom);
}
