import { Trans } from '@lingui/react/macro';
import { GameMetaData } from '../types';
import ThumbnailA from './assets/Thumbnail_Kiwi.png';

export default {
  name: 'Infinicards',
  primaryAccentColor: 'black',
  secondaryAccentColor: 'white',
  thumbnailImage: ThumbnailA,
  taglines: [<Trans>Fill-in-the-blank</Trans>, <Trans>Hilarious</Trans>],
  description: '',
  elevatorPitch: (
    <Trans>
      Turn your inside jokes into cards.
      <br />
      First to three points wins!
    </Trans>
  ),
  minPlayers: 1,
  quickBits: [
    {
      emoji: '⏱️',
      text: <Trans>5-10 mins</Trans>,
    },
    {
      emoji: '👥',
      text: <Trans>1+ players</Trans>,
    },
  ],
  howToSlides: [],
  unsupportedLocales: ['ru'],
} satisfies GameMetaData;
